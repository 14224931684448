html,
body {
  margin: 0;
  background-color: #1C262C;
}

.app {
  display: flex;
  background-color: #1C262C;
  min-height: 100vh;
  color: white;
}

.content-section {
  margin-top: 10%;
  margin-left: 5%;
  margin-right: 5%;

  .avatar-text-container {
    display: flex;
    flex-direction: row;

    .avatar {
      height: 21em;
      margin-bottom: 60px;
    }

    .avatar-text {
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      padding-left: 50px;
    }
  }

  .intro {
    font-size: 1.65rem;
    color: #4CBB17;
  }

  .lrg-text {
    font-size: 4rem;
    line-height: 4rem;
  }

  .name-color {
    font-size: 5rem;
    margin-top: 2%;
    color: lightgray;
  }

  .name-desc-color {
    margin-top: 2%;
    color: grey;

  }

  .description {
    margin-top: 2%;
    font-size: 1.75rem;
    color: rgb(195, 195, 195);
    line-height: 3.0rem;
  }
}

.link-section {
  margin-top: 5%;
  padding-bottom: 50px;
  text-align: center;

  .btn-style {
    border-radius: 6px !important;
    background-color: rgb(27, 38, 44) !important;
    border-color: #4CBB17;
    border-width: 2px;
    padding: 1.3rem 2.45rem;
    font-size: 1.3rem;
    line-height: 1.5;

    &:hover {
      border-color: rgb(27, 38, 44);
      color: #4CBB17;
    }
  }
}

.modal-content {
  width: unset !important;
}

@media screen and (max-width: 620px) {
  #atc {
    text-align: center;
    display: flex;
    flex-direction: column;

    #avatarById {
      height: 15em;
      width: 15em;
      align-self: center;
      margin-bottom: 40px;
    }

    #avatar-text {
      padding-left: 0px;
    }
  }

  #desc {
    text-align: center;
    font-size: 1.25rem;
  }

  .link-section {
    padding: 5px;
    margin-bottom: 150px;
    margin-right: 20px;
    margin-left: auto;

    .btn-style {
      padding: .65rem 1.0rem;
      font-size: .9rem;
    }
  }

  .content-section .lrg-text {
    font-size: 3.5rem;
  }
}

.App-logo {
  animation: App-logo-spin infinite 12s linear;
  position: absolute;
  right: 0;
  top: 8px;
  vertical-align: unset;
  height: 5.0vmin;
  float: right;
  margin-right: 3px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}